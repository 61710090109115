import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["listingsDiv"]; // Ensure you have a target for listingsDiv

  addLoadingState(event) {
    this.listingsDivTarget.classList.add("loading");
  }

  connect() {
    console.log('msee')
  }
}